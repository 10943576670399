
export default {
  data: () => ({
    search: '',
    applicationsLoading: false,
    applications: [],
    headers: [
      { text: 'Name', value: 'Name', align: 'left' },
      { text: 'Owning Team', value: 'FriendlyName', align: 'left' },
      { text: 'Status', value: 'AppHubStatus', align: 'left' }
    ]
  }),
  mounted() {
    this.applicationsLoading = true;
    this.applications = this.$store.state.applications.items;
    this.applicationsLoading = false;
  }
};
